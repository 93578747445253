import React from 'react';
import {Form, Field} from 'react-final-form';
import {TextField} from 'final-form-material-ui';
import Link from '@material-ui/core/Link';
import ReactTooltip from 'react-tooltip';
import {
    Typography,
    Paper,
    Grid,
    Button,
    CssBaseline,
    CardMedia
} from '@material-ui/core';
import AlgoTransaction from './AlgoTransaction';
import CircleTransaction from './CircleTransaction';
import RadioButtonsGroup from './AssetRadioButtonsGroup';
import ReCAPTCHA from 'react-google-recaptcha';
import algorandImage from './images/algorand_logo_mark_black.png';
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";

const TEST_SITE_KEY = "6Lf4qiQaAAAAAHqAgqiP9q98UuqNGT0r7Jli3yuF";
const NETWORK = "Testnet";
const ALGO_DIST_AMOUNT = 5000000;  //  5 Algo
const USDC_DIST_AMOUNT = 100.0;     // 100 USDC
const USDC_ASSET_ID = 10458941;
const USDC_TOOL_TIP =`To enable dispensing USDC, provide an account that is opted into USDC, asset id ${USDC_ASSET_ID}.`
const currencyMap = {
    'algo': 'Algos',
    'usdc': 'USDC'
};
const RedTextTypography = withStyles({
    root: {
        color: "#ff0000"
    }
})(Typography);

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    group: {
        margin: `${theme.spacing.unit}px 0`,
    },
});

class App extends React.Component {
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
            callback: "not fired",
            value: "[empty]",
            selection: {value: "1"},
            load: false,
            active: true,
            captchaOK: false,
            formValid: false,
            enableSubmit: false,
            algoAccountBalance: "",
            usdcAccountBalance: "",
            enableUsdc: false,
            transactionId: "",
            currency: "algo",
            progressVariant: "determinate",
            sourceAccountString: "",
        };
        this._reCaptchaRef = React.createRef();

        this.loadAccountQueryParam();

    }

    providedAccount = "";

    loadAccountQueryParam() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let providedAccount = params.get('account');

        if (providedAccount != null) {
            if (AlgoTransaction.checkValidAccount(providedAccount)) {
                console.log(`received account parameter: ${providedAccount}`);
                this.providedAccount = providedAccount;
            } else {
                console.warn(`ignoring non valid query param, account: '${providedAccount}'`);
            }
        }
        console.log(`state.account ${this.providedAccount}`);
    }

    updateAsset = (value) => {
        console.log("updateAsset:", value);
        this.setState({currency: value});
    };

    updateSourceAccount = (sourceAccount) => {
        console.log("updateSourceAccount: ", sourceAccount);
        var returnToSourceAccountString = `When you have finished testing, please return unused Algos to the source account: ${sourceAccount.sourceAccount}, thanks!`
        this.setState({
            sourceAccountString: returnToSourceAccountString
        });
    };

    updateAccountInfo = (accountInfo) => {
        console.log("updateAccountInfo: ", accountInfo);
        let usdcAccountBalanceString = "";
        let algoBalanceString = `Algo balance:      ${accountInfo.amount / 1000000} ${currencyMap["algo"]}`;
        console.log(`asset ${JSON.stringify(accountInfo.assets)}`);
        let foundUsdc = false;
        if (accountInfo.assets != null) {
            let idx;

            for (idx = 0; idx < accountInfo['assets'].length; idx++) {
                let assetItem = accountInfo['assets'][idx];
                if (assetItem['asset-id'] === USDC_ASSET_ID) {
                    let assetItemJson = JSON.stringify(assetItem, undefined, 2);
                    console.log("assetItem = " + assetItemJson);
                    foundUsdc = true;
                    usdcAccountBalanceString = `USDC balance:      ${assetItem.amount / 1000000} ${currencyMap["usdc"]}`;
                    break;
                }
            }
        }
        this.setState({
            algoAccountBalance: algoBalanceString,
            enabledUsdc: foundUsdc,
            usdcAccountBalance: usdcAccountBalanceString
        });
    };

    updateTransactionStatus = (transactionStatus) => {
        console.log("updateTransactionStatus:", transactionStatus.transactionId);
        this.setProgress(false);
        this.setState({transactionId: transactionStatus.transactionId});
        AlgoTransaction.getAccountInfo(this.state.account, this.updateAccountInfo);
    };

    updateCircleTransactionStatus = (err, response) => {
        console.log("circle error: " + JSON.stringify(err));
        console.log("response: " + JSON.stringify(response));
        this.setProgress(false);
        AlgoTransaction.getAccountInfo(this.state.account, this.updateAccountInfo);
        if (response.transactionHash != null) {
            this.setState({transactionId: response.transactionHash});
        } else {
            this.setState({errorMessage: "Error: " + response})
        }
    };

    setProgress(enable) {
        if (enable) {
            this.setState({progressVariant: "indeterminate"});
        } else {
            this.setState({progressVariant: "determinate"});
        }
    }

    onCaptchaChange = (value) => {
        console.log("onChange Captcha value:", value);
        this.setState({captcha: value});
        if (value === null)
            this.setState({captchaOK: false, active: true});
        else {
            this.setState({captchaOK: true});
            if (this.state.account != null) {
                AlgoTransaction.getAccountInfo(this.state.account, this.updateAccountInfo);
            }
            AlgoTransaction.getSourceAccount(this.updateSourceAccount);
        }
    };

    asyncScriptOnLoad = () => {
        this.setState({callback: "called!"});
        console.log("scriptLoad - reCaptcha Ref ", this._reCaptchaRef);
    };

    onSubmit = async values => {
        // set active to false after first submit
        this.setState({active: false, transactionId: ""});

        if (this.state.currency === "algo") {
            AlgoTransaction.createAndSendTransaction(values.account, ALGO_DIST_AMOUNT, this.updateTransactionStatus, this.state.captcha);
            this.setProgress(true);
        } else if (this.state.currency === "usdc") {
            CircleTransaction.transferUSDC(values.account, USDC_DIST_AMOUNT, this.updateCircleTransactionStatus, this.state.captcha);
            this.setProgress(true);
        } else {
            console.error(`unknown currency ${this.state.currency}`)
        }
    };

    validate = values => {
        this.setState({algoAccountBalance: "", enabledUsdc: false, usdcAccountBalance: ""});
        this.setState({enableSubmit: false});
        const errors = {};
        if (!values.account) {
            errors.account = 'Required';
        } else if (values.account.length !== 58) {
            errors.account = 'Wrong length';
        } else if (!AlgoTransaction.checkValidAccount(values.account)) {
            errors.account = 'Invalid account address';
        } else {
            this.setState({formValid: true, account: values.account});
            if (this.state.captchaOK && this.state.active)
                AlgoTransaction.getAccountInfo(values.account, this.updateAccountInfo);
        }
        return errors;
    };


    render() {
        // const {classes} = this.props;

        return <div style={{padding: 16, margin: 'auto', maxWidth: 800}}>
            <CssBaseline/>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Algorand {NETWORK} Dispenser
                    </Typography>
                </Grid>
                <Grid container item xs={6} margin={'auto'} className="py-3" >
                    <ReCAPTCHA
                        ref={this._reCaptchaRef}
                        onChange={this.onCaptchaChange}
                        sitekey={TEST_SITE_KEY}
                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                    >
                    </ReCAPTCHA>
                </Grid>
                <Grid container item xs={6}>
                    <CardMedia
                        component="img"
                        alt="Algorand Logo"
                        height="150"
                        image={algorandImage}
                        title="Algorand"
                    />
                </Grid>
            </Grid>


            <Typography xs={6} gutterBottom>
                {`Fund your Algorand ${NETWORK} Account`}
            </Typography>
            <Form
                onSubmit={this.onSubmit}
                initialValues={{account: this.providedAccount}}
                validate={this.validate}

                render={({handleSubmit, reset, submitting, pristine, values}) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Paper style={{padding: 16}}>

                            <Grid container alignItems="flex-start" spacing={2}>

                                <Grid container item xs={5} data-tip={USDC_TOOL_TIP} >
                                    <RadioButtonsGroup {...this.state} algoDistAmount={ALGO_DIST_AMOUNT/1000000} usdcDistAmount={USDC_DIST_AMOUNT} onAssetChange={this.updateAsset.bind(this)} />
                                    <ReactTooltip delayShow={500} type={'info'} />
                                </Grid>
                                <Grid item xs={2}>
                                    <CircularProgress variant={this.state.progressVariant} progress={0}/>
                                </Grid>
                                <Grid container item xs={5}>
                                    <Grid container alignItems="flex-start" spacing={2}>
                                        <Grid container item xs={12}>
                                            <Typography gutterBottom>
                                                {this.state.algoAccountBalance}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Typography gutterBottom>
                                                {this.state.usdcAccountBalance}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Typography gutterBottom>
                                        The dispensed Algos and USDC have no monetary value and should only be used to
                                        test applications.
                                        This service is gracefully provided to enable development on the Algorand
                                        blockchain test networks.
                                        Please do not abuse it by requesting more Algos or USDC than needed for testing.
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Typography gutterBottom>
                                        {this.state.sourceAccountString}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Field
                                        fullWidth
                                        name="account"
                                        component={TextField}
                                        label="Algorand Account"
                                        variant="outlined"
                                        value={this.state.account}
                                    />
                                </Grid>

                                <Grid container item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={!(this.state.captchaOK && this.state.formValid && this.state.active)}
                                    >
                                        Dispense
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </form>
                )}
            />
            <Grid container>
                <Grid container item xs={12}>
                    <Typography gutterBottom>
                        {"Account Address: "}
                            {this.state.account}
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Typography gutterBottom>
                        {"Transaction ID: "}
                            {this.state.transactionId}
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <RedTextTypography gutterBottom>
                        {this.state.errorMessage}
                    </RedTextTypography>
                </Grid>
            </Grid>
        </div>
    };
}

export default App
